.entry-content {
  p {
    font-weight: 600;
    font-size: em(18px);
    margin: 25px 0;
  }
  img {
    max-width: 100%;
    height: auto;
    margin: 25px 0;
  }
  a {
    color: $color-gold-base;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}
