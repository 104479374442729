.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 300px;
  transition: top 1s ease;
  > div {
    position: relative;
  }
  &__wrap {
    background: url("#{$root-images}header.png") repeat-x 0 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 90;
    height: 300px;
    &--without-shadow {
      background-image: none;
    }
  }
  &.scrolled {
    background-color: $color-gray-dark;
    position: fixed;
    top: -100px;
    right: 0;
    left: 0;
    height: 60px;
    opacity: 0;
    .logo {
      top: 10px;
      img {
        display: none!important;
        &.img-scroll {
          display: block!important;
        }
      }
    }
    .nav {
      margin-top: 0;
    }
    .nav__container>ul>li>div {
      padding-top: 19px;
    }
  }
}
.logo {
  position: absolute;
  top: 45px;
  left: 15px;
}
.nav {
  background: url("#{$root-images}line.png") no-repeat right bottom;
  position: relative;
  float: right;
  width: 790px;
  height: 50px;
  padding-top: 15px;
  padding-bottom: 20px;
  margin-top: 80px;
  &__container {
    > ul {
      > li {
        position: relative;
        float: left;
        margin-left: 30px;
        > a {
          color: $color-white;
          font-weight: 300;
          font-size: em(14px);
          line-height: 15px;
          text-transform: uppercase;
          text-decoration: none;
          transition: color .3s ease;
        }
        &:hover {
          > a {
            color: $color-gold-base;
          }
        }
        > div {
          text-align: left;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 9999;
          width: 220px;
          padding-top: 15px;
          opacity: 0;
          visibility: hidden;
          transition: visibility 0s linear 0.3s, opacity 0.3s linear;
          > ul {
            background-color: rgba(255,255,255,0.75);
            padding: 5px 0;
            border-top: 2px solid $color-white;
            > li {
              padding: 5px 0;
              > a {
                color: $color-gray-light;
                font-weight: 600;
                font-size: em(14px);
                text-transform: uppercase;
                display: block;
                padding: 5px 15px;
              }
            }
          }
        }
        &:hover {
          > div {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
          }
        }
      }
    }
  }
}
.lang {
  font-weight: 300;
  font-size: em(14px);
  line-height: 15px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  right: 13px;
  bottom: 17px;
  width: 30px;
  > a {
    color: $color-white;
    text-decoration: none;
  }
}
