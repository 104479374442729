.footer {
  color: $color-white;
  background-color: $color-gray-dark;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 100px;
  &--extension {
    padding-top: 0;
    &-without-title {
      .footer__links {
        padding-top: 15px!important;
      }
    }
  }
  > div {
    position: relative;
  }
  .tamansari {
    color: $color-white;
    font-weight: 700;
    font-size: em(27px);
    line-height: 1.2;
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    left: 15px;
    padding-left: 85px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -20px;
      @include sprite("crown");
    }
  }
  .nav {
    margin-top: 0;
  }
  .tax {
    font-size: em(18px);
    margin-top: 40px;
    margin-bottom: 0;
  }
  .copy {
    font-size: em(12px);
    max-width: 300px;
    margin: 30px auto 0;
  }
  &__links {
    position: relative;
    padding: 50px 0;
    > a {
      color: $color-white;
      font-weight: 700;
      font-size: em(24px);
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      padding-right: 25px;
      border-bottom: 2px solid $color-gold-base;
      margin-right: 30px;
      transition: color .3s ease;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: $color-gold-base;
      }
      &.up {
        text-indent: -9999px;
        position: absolute;
        top: 50px;
        right: -200px;
        border: 0;
        margin: 0;
        @include sprite("arrow-top");
      }
    }
  }
  &__title {
    font-weight: 600;
    font-size: em(27px);
    line-height: 1.2;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding: 0 200px;
    margin-bottom: 50px;
    &::before {
      content: "";
      background: url("#{$root-images}flowers-left.png") no-repeat 0 0;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      left: 0;
      width: 177px;
      height: 58px;
      margin-top: -29px;
    }
    &::after {
      content: "";
      background: url("#{$root-images}flowers-right.png") no-repeat 0 0;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      right: 0;
      width: 177px;
      height: 58px;
      margin-top: -29px;
    }
  }
}
