// phone portrait

@media (max-width: map-get($grid-breakpoints, sm) - 1) {
	/*
	.comingsoon {
		background-image: url("#{$root-images}comingsoon/new/1280x720.jpg");
		.intro-space {
      h1 {
				transform: translateY(-35%);	  		
        span {
						&.normal {				
	            font-size: em(11px);		            
	          }					
            &.huge {
              margin-top: 100px;	    
              font-size: em(25px);	        
            }
        }    
      }
    }	 		
	}
	*/

  .content-header {
    h2 {
      padding: 0;
      &::before,
      &::after {
        display: none;
      }
    }
  }
  .s-news {
    &__nav {
      font-size: em(16px);
      > a {
        &.left,
        &.right {
          position: relative;
          top: auto;
          right: auto;
          left: auto;
        }
        &.right {
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .voucher-order {
    margin-top:60px;
  }
}

