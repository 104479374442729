// tablet
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
  .intro-space h1 {
    font-size: em(40px);
  }
  .voucher {
    .flowers {
      background-size: contain;
      width: 100%;
    }
    &__content {
      .flowers {
        span.span-2-flower {
          bottom: 15px;
        }
        span.span-3 {
          top: -30px;
          right: 0;
        }
        span.span-4-flower {
          bottom: 10px;
        }
        span.span-4 {
          right: -15px;
          bottom: -48px;
        }
      }
    }
  }

  .content-header-voucher {
    h2 {
      font-size: em(28px);
    }
    p {
      text-align: center;
      font-size: em(18px);
    }
  }

  #carousel_inner{
    width: 630px;
    margin-left: 30px;
  }

  #carousel_ul li {
    float: left;
    width: 630px;
    height: 400px;
  }

  .comingsoon {
		background-image: url("#{$root-images}comingsoon/new/1280x720.jpg");
    .intro-space {
      h1 {
        transform: translateY(-35%);
        span {
            &.huge {
              margin-top: 150px;
            }
        }    
      }
    }		    
	}  

  #gallery {
    padding: 0 50px;
  }
  .new {
    h2 {
      font-size: em(18px);
    }
    p {
      font-size: em(16px);
    }
    &__detail,
    &__date {
      font-size: em(16px);
    }
  }

  .voucher-order {
    margin-top:180px;
  }
}
