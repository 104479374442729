// phone landscape
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1) {
	/*
	.comingsoon {
		//background-image: url("#{$root-images}comingsoon/375x666.jpg");
		.intro-space {
      h1 {
				transform: translateY(-45%);	  		
        span {
						&.normal {				
	            font-size: em(11px);		            
	          }					
            &.huge {
              margin-top: 100px;	    
              font-size: em(25px);	        
            }
        }    
      }
    }	 				   
	}
	*/
}
@media (max-width: map-get($grid-breakpoints, md) - 1) and (orientation:landscape) {
	.comingsoon {
		background-image: url("#{$root-images}comingsoon/new/1280x720.jpg");
		.intro-space {
      h1 {
				transform: translateY(-25%);	  		
        span {
						&.normal {				
	            font-size: em(11px);		            
	          }					
            &.huge {
              margin-top: 50px;	    
              font-size: em(25px);	        
            }
        }    
      }
    }	 		
	}

	#carousel_inner{
		width: 430px;
		margin-left: 8%;
	}

	#carousel_ul li {
		width: 430px;
		height: 286px;
	}

	.welcome {
		h1 {
			padding-top: 30vh;
			margin-bottom: 0 0 0 0;
		}
		font-size: 40%;

		p {
			margin: 0.2em;
			min-height: auto;
			line-height: 1;
		}
	}

  .s-news {
    &__nav {
      font-size: em(16px);
      > a {
        &.center {
          margin-top: 100px;
        }
      }
    }
  }
}
