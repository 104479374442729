// tablet landscape
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
  .header.scrolled {
    .logo {
      top: 15px;
    }
  }
  .logo {
    top: 25px;
    width: 155px;
  }
  .nav {
    width: 700px;
    margin-top: 50px;
    &__container {
      > ul {
        > li{
          margin-left: 20px;
        }
      }
    }
  }
  .footer {
    .tamansari {
      font-size: em(18px);
      line-height: 36px;
    }
    &__links {
      > a.up {
        right: 0;
      }
    }
  }
  
  .welcome {
	  h1 {
		  padding-top: 33vh;
	  }
	  font-size: 80%;
  }


  .content-header-voucher {
    h2 {
      font-size: em(28px);
    }
    p {
      text-align: left;
      font-size: em(15px);
    }
  }

  #carousel_inner{
    width: 430px;
    margin-left: 30px;
  }

  #carousel_ul li {
    float: left;
    width: 430px;
    height: 286px;
  }

  .voucher-order {
    margin-top: 180px;
  }

}
