.sticky-nav {
  position: fixed;
  top: 50%;
  right: 5%;
  z-index: 80;
  margin-top: -59px;
  >li {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    >a {
      background-color: $color-gold-base;
      text-indent: -9999px;
      display: block;
      width: 22px;
      height: 22px;
      border: 2px solid transparent;
      border-radius: 50%;
      &.is-active {
        background-color: transparent;
        border-color: #9f1e24;
      }
    }
  }
}

.intro-space {
  display: block;
  height: 100vh;
  position: relative;
  z-index: 1;
  h1 {
    color: $color-white;
    font-weight: 700;
    font-size: em(56px);
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 10;
    margin-bottom: 0;
    transform: translateY(-50%);
  }
}

.intro {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -200;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  height: 100vh;
  .slick-slide {
    position: relative;
    .intro__slide {
      transform: scale(1.3);
      transition: transform 6s linear;
      &.in {
        transform: scale(1);
        transition-duration: 0;
      }
    }
  }
  &__slide {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100vh;
  }
}

.continue-arrow {
  position: absolute;
  bottom: 35px;
  left: 50%;
  z-index: 55;
  margin-left: -45.5px;
  cursor: pointer;
  @include sprite("arrow-bottom");
  &:hover {
    animation: bounce 2s infinite;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.place {
  z-index: 3;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  &::before {
    content: "";
    background: url("#{$root-images}flowers-bottom.png") no-repeat center 0;
    position: absolute;
    right: 0;
    top: -57px;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 57px;
  }
  &::after {
    content: "";
    background: url("#{$root-images}flowers-bottom.png") no-repeat center 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 57px;
  }
  &__header {
    color: $color-white;
    background-color: $color-gray-dark;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    >div {
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 210px;
    }
    h2 {
      font-weight: 600;
      font-size: em(27px);
      line-height: 1.2;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      margin-bottom: 0;
      &::before {
        content: "";
        background: url("#{$root-images}flowers-left.png") no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        left: -190px;
        width: 177px;
        height: 58px;
        margin-top: -29px;
      }
      &::after {
        content: "";
        background: url("#{$root-images}flowers-right.png") no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        right: -190px;
        width: 177px;
        height: 58px;
        margin-top: -29px;
      }
    }
  }
}

#map {
  width: 100%;
  height: 100vh;
}

.contact {
  /*  background: url("#{$root-images}contact.jpg") no-repeat center center;
  background-size: cover;*/
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  &::before {
    content: "";
    background: url("#{$root-images}flowers-bottom.png") no-repeat 0 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 10;
    width: 1920px;
    height: 57px;
    margin-left: -960px;
  }
  &__header {
    color: $color-white;
    background-color: $color-gray-dark;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    >div {
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 210px;
    }
    h2 {
      font-weight: 600;
      font-size: em(27px);
      line-height: 1.2;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      margin-bottom: 0;
      &::before {
        content: "";
        background: url("#{$root-images}flowers-left.png") no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        left: -190px;
        width: 177px;
        height: 58px;
        margin-top: -29px;
      }
      &::after {
        content: "";
        background: url("#{$root-images}flowers-right.png") no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        right: -190px;
        width: 177px;
        height: 58px;
        margin-top: -29px;
      }
    }
  }
  &__content {
    >div {
      color: $color-white;
      font-weight: 600;
      font-size: em(32px);
      line-height: 1.2;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 100vh;
      min-height: 960px;
      padding-top: 210px;
      a {
        color: $color-white;
      }
    }
  }
}

.voucher {
  background: url("#{$root-images}voucher.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    background: url("#{$root-images}flower-bottom-last.png") no-repeat 0 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 10;
    width: 1920px;
    height: 62px;
    margin-left: -960px;
  }
  &__header {
    color: $color-white;
    background-color: $color-gray-dark;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    >div {
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 210px;
    }
    h2 {
      font-weight: 600;
      font-size: em(27px);
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      margin-bottom: 0;
      &::before {
        content: "";
        background: url("#{$root-images}flowers-left.png") no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        left: -190px;
        width: 177px;
        height: 58px;
        margin-top: -29px;
      }
      &::after {
        content: "";
        background: url("#{$root-images}flowers-right.png") no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        right: -190px;
        width: 177px;
        height: 58px;
        margin-top: -29px;
      }
    }
  }
  &__content {
    font-weight: 700;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    >div {
      color: $color-white;
      text-align: center;
      height: 100vh;
      min-height: 960px;
      padding-top: 280px;
    }
    .flowers {
      background: url("#{$root-images}flowers.png") no-repeat;
      position: relative;
      width: 999px;
      height: 298px;
      margin: 0 auto;
      p {
        font-size: em(32px);
      }
      span {
        font-size: em(34px);
        text-transform: uppercase;
        position: absolute;
        &.span-1 {
          top: -45px;
          left: -90px;
          padding-top: 0;
          &-flower {
            position: absolute;
            top: 0;
            left: 40px;
            @include sprite("kvet-1");
          }
        }
        &.span-2 {
          bottom: -55px;
          left: -60px;
          &-flower {
            position: absolute;
            bottom: 0;
            left: -28px;
            @include sprite("kvet-2");
          }
        }
        &.span-3 {
          top: -25px;
          right: -15px;
          &-flower {
            content: "";
            position: absolute;
            top: 15px;
            right: 80px;
            @include sprite("kvet-3");
          }
        }
        &.span-4 {
          right: -60px;
          bottom: -55px;
          &-flower {
            content: "";
            position: absolute;
            right: -12px;
            bottom: -8px;
            @include sprite("kvet-4");
          }
        }
      }
    }
  }
  a.voucher__button {
    color: $color-white;
    font-weight: 700;
    font-size: em(48px);
    line-height: 112px;
    text-decoration: none;
    text-shadow: none;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    bottom: 100px;
    left: 50%;
    width: 330px;
    height: 115px;
    border: 1px solid $color-white;
    margin-left: -165px;
    transition: color, background-color .3s ease;
    &::before {
      content: "";
      position: absolute;
      top: -50px;
      left: 50%;
      margin-left: -55px;
      @include sprite("bow");
    }
    &:hover {
      color: $color-gray-dark;
      background-color: $color-white;
    }
  }
}

.comingsoon {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .intro-space {
    h1 {
      text-shadow: 0 0 10px #000000;
      span {
        display: block;
        &.normal {
          text-transform: none;
          font-size: em(7px);
        }
        &.huge {
          margin-top: 200px;
          font-size: em(20px);
        }
      }
    }
  }
}

.welcome {
  display: block;
  height: 100vh;
  position: relative;
  z-index: 1;
  text-align: center;
  color: $color-white;
  text-shadow: 0 0 10px #000000;
  h1 {
    color: $color-white;
    font-weight: 700;
    font-size: em(56px);
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 0.5em 0;
    padding: 0;
    padding-top: 30vh;
  }
  h3 {
    color: $color-white;
    font-weight: 700;
    font-size: em(36px);
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    padding: 0;
    margin-top: 1em;
  }
  a {
    color: $color-white;
  }
  .link {
    font-size: em(36px);
    font-weight: 700;
    text-decoration: underline;
  }
  p {
    font-size: em(26px);
    font-weight: 700;
    margin: 0 0 0.5em 0;
    padding: 0;
    min-height: 43px;
    line-height: 43px;
  }
  .number {
    font-size: em(26px);
  }
  a.underline {
    text-decoration: underline;
  }
}

.page-header {
  color: $color-white;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  &::before {
    content: "";
    background: url("#{$root-images}flowers-bottom.png") no-repeat center 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 57px;
  }
  &::after {
    content: "";
    background: url("#{$root-images}header-gradient.png") repeat-x 0 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 555px;
  }
  .body--about & {
    background-image: url("#{$root-images}tamansari_01.jpg");
  }
  .body--procedure & {
    background-image: url("#{$root-images}tamansari_03.jpg");
  }
  .body--news & {
    background-image: url("#{$root-images}tamansari_12.jpg");
  }
  .body--contact & {
    background-image: url("#{$root-images}tamansari_14.jpg");
  }
  &__middle {
    position: relative;
    z-index: 2;
    display: table;
    width: 100%;
    >div {
      vertical-align: middle;
      display: table-cell;
      height: 555px;
    }
    h1 {
      font-weight: 600;
      font-size: em(55px);
      text-transform: uppercase;
    }
  }
}

.content-header {
  color: $color-white;
  background-color: $color-gray-dark;
  text-align: center;
  &.bg-none {
    background-color: transparent;
  }
  >div {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 210px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  h2 {
    font-weight: 600;
    font-size: em(27px);
    line-height: 1.2;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding: 0 185px;
    margin-bottom: 0;
    &::before {
      content: "";
      background: url("#{$root-images}flowers-left.png") no-repeat 0 0;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      left: 0;
      width: 177px;
      height: 58px;
      margin-top: -29px;
    }
    &::after {
      content: "";
      background: url("#{$root-images}flowers-right.png") no-repeat 0 0;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      right: 0;
      width: 177px;
      height: 58px;
      margin-top: -29px;
    }
  }
}

.voucher-anchor{
  margin-top: -60px;
}

.content-header-voucher{
  padding-bottom: 10px;
  padding-top: 60px;
  h2 {
    color: #bdaa2d;
    font-weight: 600;
    font-size: em(40px);
    line-height: 0.9;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding: 0 235px;
    margin-bottom: 40px;
    margin-top: 10px;
    &::before {
      content: "";
      background: url("#{$root-images}flowers-left.png") no-repeat 0 0;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      left: 0;
      width: 177px;
      height: 58px;
      margin-top: -29px;
    }
    &::after {
      content: "";
      background: url("#{$root-images}flowers-right.png") no-repeat 0 0;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      right: 0;
      width: 177px;
      height: 58px;
      margin-top: -29px;
    }
  }
  p {
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    font-size: em(20px);
  }
  strong {
    color: #bdaa2d;
  }
  small {
    text-transform: lowercase;
    font-size: 100%;
  }
  .number-voucher{
    font-family: "Times New Roman", Times, serif;
  }
  a {
    color: #bdaa2d;
    text-decoration: underline;
    &:hover{
      color: gold;
    }
  }
}

#carousel_inner {
  float: left;
  width: 580px;
  overflow: hidden;
}

#carousel_ul li img {
  width: 100%;
  height: 100%;
}

#carousel_ul {
  position: relative;
  //left: -210px;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  width: 9999px;
  padding-bottom: 10px;
}

#carousel_ul li {
  float: left;
  width: 580px;
  padding: 0px;
  height: 386px;
  background: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

#carousel_ul li img {
  margin-bottom: -4px;
  border: 0;
}

#left_scroll, #right_scroll {
  float: left;
  height: 130px;
  width: 35px;
  margin-top: 50px;
  margin-left: 20px;
}

#left_scroll img, #right_scroll img {
  border: 0;
  /* remove the default border of linked image */
  /*styling*/
  cursor: pointer;
  cursor: hand;
}

#left_scroll img {
  width: 25px;
  height: 25px;
}

#right_scroll img {
  width: 25px;
  height: 25px;
}

.about {
  &__links {
    margin-top: 25px;
    a {
      color: $color-white;
      font-weight: 700;
      font-size: em(18px);
      text-decoration: none;
      text-transform: uppercase;
      border-bottom: 2px solid $color-gold-base;
      transition: color .3s ease;
      background: transparent;
      &:first-child {
        margin-right: 30px;
      }
      &:hover {
        color: $color-gold-base;
      }
    }
  }
}

.body {
  &--about {
    .page {
      &__section {
        color: $color-white;
        position: relative;
        overflow: hidden;
        &::before {
          content: "";
          background: url("#{$root-images}flower-bottom-last.png") no-repeat 0 0;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 50%;
          z-index: 10;
          width: 1920px;
          height: 62px;
          margin-left: -960px;
        }
      }
      &__content {
        background: url("#{$root-images}tamansari_02.jpg") no-repeat;
        background-size: cover;
        padding: 100px 0 150px;
      }
      &__entry {
        padding-bottom: 100px;
      }
    }
  }
  &--contact {
    .page {
      &__section {
        color: $color-white;
        position: relative;
        overflow: hidden;
        &::before {
          content: "";
          background: url("#{$root-images}flower-bottom-last.png") no-repeat 0 0;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 50%;
          z-index: 10;
          width: 1920px;
          height: 62px;
          margin-left: -960px;
        }
      }
      &__content {
        background: url("#{$root-images}tamansari_02.jpg") no-repeat;
        background-size: cover;
        padding: 100px 0 150px;
        h3 {
          color: $color-gold-base;
          font-size: em(24px);
          line-height: 1.2;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        p {
          font-weight: 600;
          font-size: em(20px);
          line-height: 1.2;
          span,
          a {
            color: $color-gold-base;
          }
        }
      }
      &__map {
        color: $color-black;
        background-color: $color-white;
        height: 375px;
        margin-top: 30px;
      }
    }
  }
}

#gallery {
  padding: 0 75px;
  a {
    display: block;
    padding: 6px;
    outline: none;
  }
  .slick-prev {
    background-color: transparent;
    text-indent: -9999px;
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    outline: none;
    border: 0;
    border-radius: 0;
    margin-top: -28px;
    cursor: pointer;
    @include sprite("arrow-left");
  }
  .slick-next {
    background-color: transparent;
    text-indent: -9999px;
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    outline: none;
    border: 0;
    border-radius: 0;
    margin-top: -28px;
    cursor: pointer;
    @include sprite("arrow-right");
  }
}

.procedure {
  color: $color-white;
  &__menu {
    padding-top: 65px;
    >li {
      padding-top: 18px;
      padding-bottom: 18px;
      >a {
        color: $color-white;
        font-weight: 700;
        font-size: em(20px);
        text-transform: uppercase;
        text-decoration: none;
        display: block;
        max-width: 280px;
        border-bottom: 2px solid $color-gold-base;
        transition: color .3s ease;
        &:hover {
          color: $color-gold-base;
        }
      }
    }
  }
  &__article {
    overflow: hidden;
    &--last {
      .procedure__content {
        &::before {
          content: "";
          background: url("#{$root-images}flower-bottom-last.png") no-repeat center 0;
          height: 62px;
        }
      }
    }
  }
  &__content {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 80px;
    padding-bottom: 50px;
    background-image: url("#{$root-images}tamansari_04.jpg");
    &--cath1 {
      background-image: url("#{$root-images}tamansari_04.jpg");
    }
    &--cath2 {
      background-image: url("#{$root-images}tamansari_05.jpg");
    }
    &--cath3 {
      background-image: url("#{$root-images}tamansari_06.jpg");
    }
    &--cath4 {
      background-image: url("#{$root-images}tamansari_07.jpg");
      padding-bottom: 150px;
    }
    &--cath5 {
      background-image: url("#{$root-images}tamansari_08.jpg");
      padding-bottom: 150px;
    }
    &--cath6 {
      background-image: url("#{$root-images}tamansari_09.jpg");
    }
    &--cath7 {
      background-image: url("#{$root-images}tamansari_10.jpg");
      padding-bottom: 150px;
    }
    &--cath8 {
      background-image: url("#{$root-images}tamansari_11.jpg");
    }
    &::before {
      content: "";
      background: url("#{$root-images}flowers-bottom.png") no-repeat center 0;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 50;
      width: 100%;
      height: 57px;
    }
  }
  .row {
    &--ornament-1 {
      position: relative;
      &::before {
        content: "";
        background: url("#{$root-images}ornament_01.png") no-repeat 0 0;
        position: absolute;
        top: -30px;
        left: 50%;
        width: 122px;
        height: 568px;
        margin-left: -100px;
      }
    }
    &--ornament-2 {
      position: relative;
      &::before {
        content: "";
        background: url("#{$root-images}ornament_02.png") no-repeat 0 0;
        position: absolute;
        top: -40px;
        left: 50%;
        width: 122px;
        height: 568px;
        margin-left: -30px;
      }
    }
    >div {
      position: relative;
      z-index: 1;
      margin-bottom: 50px;
    }
  }
  &__item {
    &--left {
      height: 100%;
      padding-right: 15px;
    }
    &--right {
      height: 100%;
      padding-left: 15px;
    }
    >div {
      background-color: $color-gray-dark;
      height: 100%;
      padding: 45px 25px;
    }
    h3 {
      color: $color-gold-base;
      font-weight: 600;
      font-size: em(24px);
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    p {
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

.news {
  &__content {
    color: $color-white;
    padding-top: 40px;
    >div {
      background: url("#{$root-images}tamansari_13.jpg") no-repeat;
      background-size: cover;
      position: relative;
      padding-top: 50px;
      padding-bottom: 100px;
      overflow: hidden;
      &::before {
        content: "";
        background: url("#{$root-images}flower-bottom-last.png") no-repeat 0 0;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 10;
        width: 1920px;
        height: 62px;
        margin-left: -960px;
      }
    }
  }
  &__button {
    text-align: center;
    >a {
      color: $color-white;
      font-weight: 700;
      font-size: em(20px);
      text-transform: uppercase;
      text-decoration: none!important;
      display: inline-block;
      padding-right: 25px;
      border-bottom: 2px solid $color-gold-base;
      transition: color .3s ease;
      &:hover {
        color: $color-gold-base;
      }
    }
  }
}

.new {
  margin-bottom: 30px;
  >div {
    background-color: $color-gray-dark;
    position: relative;
    height: 100%;
    padding: 25px 25px 55px;
  }
  h2 {
    font-weight: 600;
    font-size: em(20px);
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    padding: 0 110px;
    margin-bottom: 30px;
    &::before {
      content: "";
      background: url("#{$root-images}flowers-left-small.png") no-repeat;
      position: absolute;
      top: 50%;
      left: 0;
      width: 96px;
      height: 32px;
      margin-top: -16px;
    }
    &::after {
      content: "";
      background: url("#{$root-images}flowers-right-small.png") no-repeat;
      position: absolute;
      top: 50%;
      right: 0;
      width: 96px;
      height: 32px;
      margin-top: -16px;
    }
    >a {
      color: $color-gold-base;
      text-decoration: none;
    }
  }
  p {
    font-weight: 600;
    font-size: em(18px);
  }
  &__detail {
    color: $color-white;
    font-weight: 700;
    font-size: em(18px);
    text-transform: uppercase;
    text-decoration: none!important;
    position: absolute;
    bottom: 25px;
    left: 25px;
    display: inline-block;
    padding-right: 25px;
    border-bottom: 2px solid $color-gold-base;
    transition: color .3s ease;
    &:hover {
      color: $color-gold-base;
    }
  }
  &__date {
    color: $color-gold-base;
    font-weight: 600;
    font-size: em(18px);
    position: absolute;
    right: 25px;
    bottom: 25px;
  }
}

.s-voucher {
  color: $color-white;
  text-align: center;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    background: url("#{$root-images}flower-bottom-last.png") no-repeat 0 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 10;
    width: 1920px;
    height: 62px;
    margin-left: -960px;
  }
  &__content {
    background: url("#{$root-images}tamansari_15.jpg") no-repeat;
    background-size: cover;
    padding: 100px 0 150px;
    .row {
      >div {
        margin-bottom: 30px;
      }
    }
  }
}

.voucher-i {
  color: $color-white;
  background-color: $color-gray-dark;
  height: 100%;
  padding: 25px;
  h3 {
    color: $color-gold-base;
    font-weight: 600;
    font-size: em(24px);
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  img {
    margin-bottom: 15px;
  }
  a {
    color: $color-white;
    font-weight: 700;
    font-size: em(18px);
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 2px solid $color-gold-base;
    transition: color .3s ease;
    &:hover {
      color: $color-gold-base;
    }
  }
}

.s-news {
  color: $color-white;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    background: url("#{$root-images}flower-bottom-last.png") no-repeat 0 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 10;
    width: 1920px;
    height: 62px;
    margin-left: -960px;
  }
  &__content {
    background: url("#{$root-images}tamansari_02.jpg") no-repeat;
    background-size: cover;
    padding: 100px 0 150px;
  }
  &__nav {
    font-weight: 700;
    font-size: em(18px);
    text-transform: uppercase;
    text-align: center;
    position: relative;
    margin-top: 50px;
    >a {
      color: $color-white;
      text-decoration: none;
      display: inline-block;
      border-bottom: 2px solid $color-gold-base;
      transition: color .3s ease;
      &.left {
        line-height: 20px;
        position: absolute;
        top: 50%;
        left: 25px;
        margin-top: -10px;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: -25px;
          margin-top: -15px;
          @include sprite("arrow-left-small");
        }
      }
      &.right {
        line-height: 20px;
        position: absolute;
        top: 50%;
        right: 25px;
        margin-top: -10px;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: -25px;
          margin-top: -15px;
          @include sprite("arrow-right-small");
        }
      }
      &.center {
        padding-right: 25px;
      }
      &:hover {
        color: $color-gold-base;
      }
    }
  }
}


/*Voucher order LF */

.wrap-bg {
  background: url("#{$root-images}Tamansari_checkout.jpg");
  background-attachment: fixed;
  background-size: cover;
}

.voucher-order {
  margin-top: 250px;
  border-top: 1px solid $color-white;
  backgroudn: transparent;
  color: #fff;
  min-height: 500px;
  h1 {
    text-align: center;
    color: #fff;
  }
  .voucher-value {
    span {
      color: $color-gold-base;
    }
  }
  .color-gold-base {
    color: $color-gold-base;
  }
  .total-sum {
    background: #1d1d1d;
    p {
      font-size: em(20px);
    }
    .total-price {
      font-size: em(27px);
    }
  }


  
}
  .has-error {
    padding: 0px;
    background: red url(#{$root-images}warning.png) 2px center no-repeat!important;
    margin-top: 20px;
    display: none;
    color: #fff;
    position: relative;
    font-style: normal !important;
    padding: 1px 10px 1px 25px;
    &:after {
      content: "";
      width: 0;
      height: 0;
      top: -10px;
      right: 20px;
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid red;
    }
  }

.step2,
.step3 {
  display: none;
  &.active {
    display: block;
    transition: all 0.8s ease;
  }
}

.step3 {
  color: #fff;

  .btn-submit {
    color: $color-white;
    font-weight: 700;
    font-size: em(17px);
    text-decoration: none;
    text-transform: uppercase;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 2px solid $color-gold-base;
    transition: color .3s ease;
    background: transparent;
    outline: 0;
    &:hover {
      color: $color-gold-base !important;
      cursor: pointer;
    }
  }

  input[type="radio"]:checked ~ label {
    color: $color-gold-base;
    border-color: $color-gold-base;

    &:after{
      display:block;
    }
  }

   

}
 .col-sm-6>input[type="radio"] + label:after{
    content: url(/assets/images/bg/check.png);
    min-width: 20px;
    min-height: 20px;
    position: absolute;
    right: 3px;
    display: flex;
    font-size: 20px;
    top: -12px;
    z-index: 999;
    display:none;
    }

.order-form,
.product-form {
  p,
  input,
  span,
  div {
    font-size: em(17px);
  }
  border-bottom: 1px solid $color-white;
  &.border-none {
    border: none;
  }
  article {
    padding-bottom: 20px;
    border-bottom: 2px solid #31302f;
    &:nth-child(1n) {
      padding-top: 20px;
    }
    &:last-child {
      border-bottom: 0px;
    }
    .thumb-image-order {
      max-height: 70px;
    }
    .input-group {
      max-width: 150px;
      border-bottom: 1px solid #fff;
      .btn-number {
        background: none;
        border: none;
        color: #fff;
      }
      .input-number,
      .input-text {
        text-align: center;
        background: none;
        border: none;
        width: 100%;
        font-size: em(20px);
        color: $color-gold-base;
      }
    }
  }
  /*Inputs + validation */
  .input-text {
    color: #fff;
    border-bottom: 1px solid $color-white !important;
    text-align: center;
    background: none;
    border: none;
    width: 100%;
    font-size: em(16px);
    margin-top: 20px;
    position: relative;
    &:focus {
      outline: 0;
      border-color: $color-gold-base;
    }
  }
  button {
    color: $color-white;
    font-weight: 700;
    font-size: em(17px);
    text-decoration: none;
    text-transform: uppercase;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 2px solid $color-gold-base;
    transition: color .3s ease;
    background: transparent;
    outline: 0;
    &:hover {
      color: $color-gold-base !important;
      cursor: pointer;
    }
  }


}

.order-form {
  input {
    background-position: right;
    &:valid {
      background: url(#{$root-images}check.png) right !important;
      background-size: 20px;
      background-repeat: no-repeat !important;
      transition: all 0.4s;
    }
    &:invalid:not(:focus):not(:placeholder-shown) {
      color: #f20d0d;
      background: url(#{$root-images}cross.png) right !important;
      background-size: 20px;
      background-repeat: no-repeat !important;
      transition: all 0.4s;
      &+label {
        opacity: 0;
      }
      &~.has-error {
        display: block;
      }
    }
    &:invalid:focus:not(:placeholder-shown) {
      &~.has-error {
        display: none;
      }
    }
  }
  .has-error {
    padding: 0px;
    background: red url(#{$root-images}warning.png) 2px center no-repeat!important;
    margin-top: 20px;
    display: none;
    color: #fff;
    position: relative;
    font-style: normal !important;
    padding: 1px 10px 1px 25px;
    &:after {
      content: "";
      width: 0;
      height: 0;
      top: -10px;
      right: 20px;
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid red;
    }
  }
}

.payment-button {
  border: 1px solid #fff;
  display: inline-flex;
  width: auto;
  align-items: center;
  padding: 15px 20px;
  color: #fff;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-content: center;
  &:hover {
    color: $color-gold-base;
    border-color: $color-gold-base;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.4s ease;
  }
}

.has-error,
.has-error input[type="email"],
.has-error select {}

/*Voucher order LF Second Step */