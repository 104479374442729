// tablet portrait
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1) {
  .header {
    background-color: #1e1e1e;
    background-image: none;
    position: fixed !important;
    height: 60px;
    > div {
      position: static;
    }
    &__wrap {
      background-image: none;
      height: auto;
    }
    &.scrolled {
      top: 0 !important;
      opacity: 1 !important;
    }
  }
  .logo {
    top: 5px;
    left: 15px;
    z-index: 110;
  }
  .nav {
    background-image: none;
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    &__opener {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 110;
      width: 60px;
      height: 60px;
      > span {
        width: 23px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotate(0deg);
        margin-top: -10px;
        margin-left: -11.5px;
        transition: .5s ease-in-out;
        > span {
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          background-color: $color-white;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: .25s ease-in-out;
          &:nth-child(1) {
            top: 0px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            top: 8px;
          }
          &:nth-child(4) {
            top: 16px;
          }
        }
      }
      &.is-active {
        > span {
          > span {
            &:nth-child(1) {
              top: 18px;
              width: 0%;
              left: 50%;
            }
            &:nth-child(2) {
              transform: rotate(45deg);
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
            }
            &:nth-child(4) {
              top: 18px;
              width: 0%;
              left: 50%;
            }
          }
        }
      }
    }
    &__container {
      background-color: $color-gray-dark;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 100;
      display: none;
      width: 100%;
      height: auto;
      padding: 15px 15px 30px;
      border-bottom: 2px solid $color-white;
      &.is-active {
        display: block;
      }
      > ul {
        padding: 5px 0;
        > li {
          float: none;
          padding: 5px 0;
          margin-left: 0;
          > a {
            font-size: em(21px);
            display: block;
            padding: 5px 0;
          }
          &.active {
            > a {
              color: $color-gold-base;
            }
          }
          > div {
            position: static;
            top: auto;
            right: auto;
            display: none;
          }
          &.doubletap {
            padding-bottom: 0;
          }
          &:hover {
            > div {
              text-align: left;
              //display: block; hide secondary NAV
              width: 100%;
              padding-top: 0;
              > ul {
                background-color: transparent;
                padding-left: 15px;
                border-top: 0;
                > li {
                  padding: 0;
                  &.active {
                    > a {
                      color: $color-gold-base;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .footer {
    padding-top: 30px;
    .tamansari {
      display: none;
    }
    &__links {
      padding-top: 75px;
      > a {
        font-size: em(18px);
        &.up {
          top: 0;
          right: auto;
          left: 50%;
          margin-left: -20.5px;
        }
      }
    }
    &__title {
      font-size: em(21px);
    }
  }
  .voucher .flowers {
    background-image: none;
    width: 100%;
    span {
      display: none;
    }
  }
  .intro__logo {
    display: table !important;
    max-width: 135px;
    margin: 0 auto 25px;
  }

  .welcome {
    h1 {
      padding-top: 33vh;
    }
    font-size: 80%;
    #gallery {
      padding: 0 50px;
    }
    .procedure {
      &__menu {
        > li {
          > a {
            font-size: em(16px);
          }
        }
      }
      .row {
        &::before {
          display: none;
        }
      }
      &__item {
        &--left {
          padding-right: 0;
        }
        &--right {
          padding-left: 0;
        }
      }
    }
    .content-header {
      h2 {
        font-size: em(21px);
      }
    }
    .s-news {
      &__nav {
        font-size: em(16px);
        > a {
          &.center {
            margin-top: 100px;
          }
        }
      }
    }
  }

  .voucher-order {
    margin-top:60px;
  }
}
