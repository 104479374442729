// phone
@media (max-width: map-get($grid-breakpoints, md) - 1) {
  .header {
    background-color: #1e1e1e;
    position: fixed !important;
    height: 60px;
    > div {
      position: static;
    }
    &__wrap {
      background-image: none;
      height: auto;
    }
    &.scrolled {
      top: 0 !important;
      opacity: 1 !important;
    }
  }
  .logo {
    top: 10px;
    left: 15px;
    z-index: 110;
    width: 190px;
  }
  .nav {
    background-image: none;
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    &__opener {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 110;
      width: 60px;
      height: 60px;
      > span {
        width: 23px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotate(0deg);
        margin-top: -10px;
        margin-left: -11.5px;
        transition: .5s ease-in-out;
        > span {
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          background-color: $color-white;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: .25s ease-in-out;
          &:nth-child(1) {
            top: 0px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            top: 8px;
          }
          &:nth-child(4) {
            top: 16px;
          }
        }
      }
      &.is-active {
        > span {
          > span {
            &:nth-child(1) {
              top: 18px;
              width: 0%;
              left: 50%;
            }
            &:nth-child(2) {
              transform: rotate(45deg);
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
            }
            &:nth-child(4) {
              top: 18px;
              width: 0%;
              left: 50%;
            }
          }
        }
      }
    }
    &__container {
      background-color: $color-gray-dark;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 100;
      display: none;
      width: 100%;
      height: auto;
      padding: 15px 15px 30px;
      border-bottom: 2px solid $color-white;
      &.is-active {
        display: block;
      }
      > ul {
        padding: 5px 0;
        > li {
          float: none;
          padding: 5px 0;
          margin-left: 0;
          > a {
            font-size: em(21px);
            display: block;
            padding: 5px 0;
          }
          &.active {
            > a {
              color: $color-gold-base;
            }
          }
          > div {
            position: static;
            top: auto;
            right: auto;
            display: none;
          }
          &.doubletap {
            padding-bottom: 0;
          }
          &:hover {
            > div {
              text-align: left;
              //display: block; hide secondary NAV
              width: 100%;
              padding-top: 0;
              > ul {
                background-color: transparent;
                padding-left: 15px;
                border-top: 0;
                > li {
                  padding: 0;
                  &.active {
                    > a {
                      color: $color-gold-base;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .continue-arrow {
    @include sprite("arrow-bottom-mobile");
    bottom: 22px;
    margin-left: -21px;
  }
  .intro-space {
    h1 {
      font-size: em(26px);
    }
  }
  .place {
    &::before {
      background: url("#{$root-images}flowers-bottom-mobile.png") no-repeat center 0;
      top: -25px;
      height: 25px;
    }
    &::after {
      background: url("#{$root-images}flowers-bottom-mobile.png") no-repeat center 0;
      height: 25px;
    }
  }
  .place,
  .contact,
  .voucher {
    &__header {
      overflow: hidden;
      > div {
        height: 90px;
      }
      h2 {
        font-size: em(11px);
        line-height: 1.2;
        &::before,
        &::after {
          background-size: 100% 100%;
          width: 60px;
          height: 20px;
          margin-top: -10px;
        }
        &::before {
          left: -75px;
        }
        &::after {
          right: -75px;
        }
      }
    }
  }
  .voucher {
    &::before {
      background: url("#{$root-images}flower-bottom-last-mobile.png") no-repeat center 0;
      height: 22px;
    }
    &__content {
      > div {
        min-height: 480px;
        padding-top: 120px;
      }
    }
    .flowers {
      background-image: none;
      width: 100%;
      p {
        font-size: em(18px);
      }
      span {
        display: none;
      }
    }
    a.voucher__button {
      font-size: em(21px);
      line-height: 58px;
      bottom: 50px;
      width: 180px;
      height: 60px;
      margin-left: -90px;
    }
  }
  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
    .tamansari {
      display: none;
    }
    &__links {
      padding: 75px 0 0;
      margin-bottom: 30px;
      > a {
        font-size: em(18px);
        display: block;
        max-width: 190px;
        padding: 0;
        margin-right: auto;
        margin-bottom: 15px;
        margin-left: auto;
        &:last-child {
          margin-right: auto;
        }
        &.up {
          top: 0;
          right: auto;
          left: 50%;
          margin-left: -20.5px;
        }
      }
    }
    &__title {
      font-size: em(21px);
      margin-bottom: 0;
      padding: 0;
      &::before,
      &::after {
        display: none;
      }
    }
  }
  .intro__logo {
    display: table !important;
    max-width: 135px;
    margin: 0 auto 25px;
  }

  .comingsoon {
    background-image: url("#{$root-images}comingsoon/new/375x666.jpg");
    .intro-space {
      h1 {
        span {
          &.huge {
            margin-top: 100px;
            font-size: em(25px);
          }
        }
      }
    }
  }

  .page-header {
    overflow: hidden;
    &::before {
      background: url("#{$root-images}flowers-bottom-mobile.png") no-repeat center 0;
      height: 25px;
      z-index: 25;
    }
    &__middle {
      > div {
        height: 250px;
        padding-top: 60px;
      }
      h1 {
        font-size: em(30px);
      }
    }
  }
  .content-header {
    overflow: hidden;
    > div {
      height: 90px;
    }
    h2 {
      font-size: em(20px);
      line-height: 1.2;
      padding: 0 70px;
      &::before,
      &::after {
        background-size: 100% 100%;
        width: 60px;
        height: 20px;
        margin-top: -10px;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
  }

  .content-header-voucher {
    overflow: hidden;
    padding-top: 0;
    h2 {
      font-size: em(20px);
      line-height: 1.2;
      padding: 0 70px;
      &::before,
      &::after {
        background-size: 100% 100%;
        width: 60px;
        height: 20px;
        margin-top: -10px;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
    p {
      font-size: em(12px);
      text-align: center !important;
    }
  }

  #carousel_inner{
    width: 330px;
  }

  #carousel_ul li {
    float: left;
    width: 330px;
    height: 219px;
  }

    #gallery {
    padding: 0 40px;
  }
  .procedure {
    &__menu {
      padding-top: 30px;
      > li {
        > a {
          font-size: em(16px);
          text-align: center;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    &__article {
      &--last {
        .procedure__content {
          &::before {
            background: url("#{$root-images}flower-bottom-last-mobile.png") no-repeat center 0;
            height: 22px;
          }
        }
      }
    }
    &__content {
      padding-top: 45px;
      &::before {
        background: url("#{$root-images}flowers-bottom-mobile.png") no-repeat center 0;
        height: 25px;
        z-index: 25;
      }
      &--couples {
        padding-bottom: 50px;
      }
      &--face-care {
        padding-bottom: 50px;
      }
      &--head {
        padding-bottom: 50px;
      }
    }
    .row {
      &::before {
        display: none;
      }
      > div {
        margin-bottom: 30px;
      }
    }
    &__item {
      &--left {
        padding-right: 0;
      }
      &--right {
        padding-left: 0;
      }
      > div {
        padding: 15px;
      }
      h3 {
        font-size: em(21px);
        margin-bottom: 20px;
      }
    }
  }
  .news {
    &__content {
      > div {
        &::before {
          background: url("#{$root-images}flower-bottom-last-mobile.png") no-repeat center 0;
          height: 22px;
        }
      }
    }
    &__button {
      > a {
        font-size: em(16px);
      }
    }
  }
  .new {
    > div {
      padding: 15px 15px 45px;
    }
    h2 {
      font-size: em(18px);
      padding: 0;
      &::before,
      &::after {
        display: none;
      }
    }
    p {
      font-size: em(16px);
    }
    &__detail,
    &__date {
      font-size: em(16px);
    }
    &__detail {
      bottom: 15px;
      left: 15px;
    }
    &__date {
      right: 15px;
      bottom: 15px;
    }
  }
  .about {
    &__links {
      > a {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }
  .s-contact {
    &::before {
      background: url("#{$root-images}flower-bottom-last-mobile.png") no-repeat center 0;
      height: 22px;
    }
    &__content {
      padding: 30px 0 50px;
      .row {
        > div:first-child {
          margin-bottom: 25px;
        }
      }
    }
  }
  .s-voucher {
    &::before {
      background: url("#{$root-images}flower-bottom-last-mobile.png") no-repeat center 0;
      height: 22px;
    }
    &__content {
      padding: 30px 0 50px;
    }
  }
  .voucher-i {
    padding: 15px;
  }
  .s-news {
    &::before {
      background: url("#{$root-images}flower-bottom-last-mobile.png") no-repeat center 0;
      height: 22px;
    }
    &__content {
      padding: 30px 0 50px;
      .row {
        > div:first-child {
          margin-bottom: 25px;
        }
      }
    }
  }

  .body {
    &--about {
      .page {
        &__section {
          &::before {
            background: url("#{$root-images}flowers-bottom-mobile.png") no-repeat center 0;
            height: 25px;
          }
        }
        &__content {
          > div {
            font-size: em(18px);
            min-height: 480px;
            padding-top: 90px;
          }
        }
      }
    }
    &--contact {
      .page {

      }
    }
  }

  .voucher-order {
    margin-top:60px;
  }
}
