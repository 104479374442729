// desktop
@media (min-width: map-get($grid-breakpoints, xl)) {
	.comingsoon {
		background-image: url("#{$root-images}comingsoon/new/1920x1280.jpg");
		.intro-space {
			h1 {
				transform: translateY(-45%);
			}
		}		
	}
	
	.welcome {
		.container-fluid {
			max-width: 1080px;
		}
	}
}

@media (min-width: map-get($grid-breakpoints, xl)) and (max-width: 1480px) {
	.welcome {
		font-size: 95%;
		h1 {
			margin: 0;
		}
		p {
			margin-bottom: 0.25em
		}
	}
}
@media (min-width: 1501px) and (max-width: 1680px) {
  .footer {
    &__links {
      > a.up {
        right: -100px;
      }
    }
  }
}
@media (min-width: 1201px) and (max-width: 1500px) {
  .footer {
    &__links {
      > a.up {
        right: 0;
      }
    }
  }
}
