$sprite: (
	arrow-bottom-mobile: (
		offset-x: -67px,
		offset-y: -109px,
		width: 44px,
		height: 22px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	arrow-bottom: (
		offset-x: 0px,
		offset-y: -53px,
		width: 91px,
		height: 46px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	arrow-left-small: (
		offset-x: -101px,
		offset-y: -156px,
		width: 15px,
		height: 30px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	arrow-left: (
		offset-x: -38px,
		offset-y: -156px,
		width: 28px,
		height: 56px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	arrow-right-small: (
		offset-x: -76px,
		offset-y: -156px,
		width: 15px,
		height: 30px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	arrow-right: (
		offset-x: 0px,
		offset-y: -156px,
		width: 28px,
		height: 56px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	arrow-top: (
		offset-x: -200px,
		offset-y: -50px,
		width: 41px,
		height: 43px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	bow: (
		offset-x: 0px,
		offset-y: 0px,
		width: 110px,
		height: 43px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	crown: (
		offset-x: -120px,
		offset-y: 0px,
		width: 70px,
		height: 36px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	kvet-1: (
		offset-x: -200px,
		offset-y: -103px,
		width: 38px,
		height: 38px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	kvet-2: (
		offset-x: 0px,
		offset-y: -109px,
		width: 57px,
		height: 37px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	kvet-3: (
		offset-x: -200px,
		offset-y: 0px,
		width: 47px,
		height: 40px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
	kvet-4: (
		offset-x: -120px,
		offset-y: -46px,
		width: 60px,
		height: 40px,
		total-width: 247px,
		total-height: 212px,
		image: 'images/bg/sprite.png'
	),
);


@mixin sprite-size($image) {
	background-size: map-get(map-get($sprite, $image), 'total-width') map-get(map-get($sprite, $image), 'total-height');
}

@mixin sprite-width($image) {
	width: map-get(map-get($sprite, $image), 'width');
}

@mixin sprite-height($image) {
	height: map-get(map-get($sprite, $image), 'height');
}

@mixin sprite-position($image) {
	background-position: map-get(map-get($sprite, $image), 'offset-x') map-get(map-get($sprite, $image), 'offset-y');
}

@mixin sprite-image($image) {
	background-image: url(map-get(map-get($sprite, $image), 'image'));
}

@mixin sprite-image-retina($image) {
	$image-path: map-get(map-get($sprite, $image), 'image');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';

	background-image: url($image-path-retina);
}

@mixin sprite($image, $size: true) {
	@include sprite-image($image);
	@include sprite-position($image);
	background-repeat: no-repeat;

	@if $size {
		@include sprite-width($image);
		@include sprite-height($image);
	}

	@media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {

		@include sprite-size($image);
		@include sprite-image-retina($image);

	}
}

